import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';

const BoxStyled = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.up('xs')]: {
    padding: "0px 20px"
  },
  [theme.breakpoints.up("sm")]: {
    padding: "0px 32px"
  },
  [theme.breakpoints.up("md")]: {
    padding: "0px 32px"

  },
  [theme.breakpoints.up("lg")]: {
    padding: "0px 32px"

  },
  [theme.breakpoints.up("xl")]: {
    padding: "0px 32px"
  },
}));

const ToorbarStyled = styled(Toolbar)(({ theme }) => ({
  padding: "0 !important",
  [theme.breakpoints.up('xs')]: {
    minHeight: "48px !important"
  },
  [theme.breakpoints.up("sm")]: {
    minHeight: "56px !important"
  },
  [theme.breakpoints.up("md")]: {
    minHeight: "56px !important"

  },
  [theme.breakpoints.up("lg")]: {
    minHeight: "56px !important"

  },
  [theme.breakpoints.up("xl")]: {
    minHeight: "56px !important"
  },
}));

type HeaderProps = {
  leftChildren: any;
  rightChildren:any;
};

const headerBar = (props: HeaderProps) => {
  return (
    <>
    <AppBar position="fixed" style={{
      /*boxShadow: "0 1px 2px rgba(0,0,0,0.1), 0 -1px rgba(0,0,0,0.1)inset,0 2px 1px -1px rgba(255,255,255,0.5) inset",*/
      boxShadow: "none",
      backgroundColor: "#212121",
    }}>
      <BoxStyled>
        <ToorbarStyled>
          <Stack direction="row" spacing={1} flexGrow={1} alignItems="center">
            {React.cloneElement(props.leftChildren)}
          </Stack>
          <Stack direction="row" spacing={1}  alignItems="center">
            {React.cloneElement(props.rightChildren)}
          </Stack>
        </ToorbarStyled>
      </BoxStyled>
    </AppBar>
    </>
  );
}

export default headerBar;
