import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

//import LocalCredentialsBanner from "./services/helpers/LocalCredentialsBanner";
//import MockDataWarning from "./services/helpers/MockDataWarning";
import Search from "./search/Search";
import { } from "./services/Kendra";
import Kendra from 'aws-sdk/clients/kendra';
import S3 from 'aws-sdk/clients/s3';
import { facetConfiguration } from "./search/configuration";
import "./App.css";
import LogoHeader from "./common/appBar/appBarChild";
import { CognitoIdentityClient, GetIdCommand, GetOpenIdTokenCommand } from '@aws-sdk/client-cognito-identity';
import { STSClient, AssumeRoleWithWebIdentityCommand } from "@aws-sdk/client-sts";
import AWSExports from './cognito-identity-credentials';
import { ThemeProvider } from '@mui/material/styles';
import AppTheme from '../src/common/themes';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { styled } from '@mui/system';
import { I18nextProvider } from 'react-i18next';
initializeFileTypeIcons(/* optional base url */);

const SearchBoxContainerStyled = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.up('xs')]: {
    paddingTop: "48px",
  },
  [theme.breakpoints.up("sm")]: {
    paddingTop: "56px",
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: "56px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingTop: "56px",
  },
  [theme.breakpoints.up("xl")]: {
    paddingTop: "56px",
  },
}));

const { AccountId, IdentityPoolId, RoleArn, RoleSessionName, indexId, region } = AWSExports;
let kendra: Kendra, s3: S3;

function App() {

  const [stateReady, setStateReady] = useState(false);
  const {i18n}=useTranslation()

  useEffect(() => {

    /* adding event listener to catch unhandled promise errors */
    window.addEventListener("unhandledrejection", (event) => {
      const reason: string = (event.reason && event.reason.toString()) || '';
      if (reason.indexOf('token') >= 0 && reason.indexOf('expire') >= 0) {
        timeOutHandler();
      }
    });

    getCognitoIdentityCredentials();
  }, []);

  const timeOutHandler = () => {
    window.location.reload();
  }

  const getCognitoIdentityCredentials = async () => {


    /* GetId API */
    let client = new CognitoIdentityClient({ region });
    const getIdCommand = new GetIdCommand({ AccountId, IdentityPoolId });
    const GetIdResponse = await client.send(getIdCommand);
    const { IdentityId } = GetIdResponse;

    /* GetOpenIdToken API */
    const getOpenIdTokenCommand = new GetOpenIdTokenCommand({ IdentityId });
    const getOpenIdTokenResponse = await client.send(getOpenIdTokenCommand);
    const { Token } = getOpenIdTokenResponse;

    /* AssumeRoleWithWebIdentity API */
    const stsClient = new STSClient({ region });
    const assumeRoleCommand = new AssumeRoleWithWebIdentityCommand({
      WebIdentityToken: Token,
      RoleSessionName,
      RoleArn
    });
    const assumeRoleResponse = await stsClient.send(assumeRoleCommand);
    const { Credentials } = assumeRoleResponse;
    const { AccessKeyId, SecretAccessKey, SessionToken } = Credentials as any;

    /* GetCredentialsForIdentity API */
    /*let CredForIndentitycommand = new GetCredentialsForIdentityCommand ({IdentityId});  
    let CredForIndentityResponse = await client.send(CredForIndentitycommand);
    console.log(CredForIndentityResponse); 
    const {AccessKeyId, SecretKey, SessionToken} = CredForIndentityResponse.Credentials as any;
    */

    /* instantiating kendra object */
    kendra = new Kendra({
      accessKeyId: AccessKeyId,
      secretAccessKey: SecretAccessKey,
      sessionToken: SessionToken,
      region
    });

    /* instantiating s3 object */
    s3 = new S3({
      accessKeyId: AccessKeyId,
      secretAccessKey: SecretAccessKey,
      sessionToken: SessionToken,
      region
    });
    setStateReady(true);
  }

  return (
    <React.Suspense fallback="loading..">
      <ThemeProvider theme={AppTheme['black']}>
        <Box sx={{ position: "relative" }}>
          <Box>
            <LogoHeader />
          </Box>
          <SearchBoxContainerStyled>
            {stateReady && <Search
              kendra={kendra}
              s3={s3}
              indexId={indexId}
              facetConfiguration={facetConfiguration}
              callBackFunc={timeOutHandler}
              resolvedLang={i18n.resolvedLanguage}
            />}
          </SearchBoxContainerStyled>
        </Box>
      </ThemeProvider>
    </React.Suspense>

  );
}

export default App;
