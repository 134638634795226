import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import Environment from './Environment';

const { aws } = Environment;
const { clientId, cognitoDomain, cognitoScope, redirectURL, region, responseType, userPoolId } = aws;

Amplify.configure({
    Auth: {
        userPoolId,
        region,
        userPoolWebClientId: clientId,
        oauth: {
            domain: cognitoDomain,
            scope: [cognitoScope],
            redirectSignIn: redirectURL,
            responseType
        }
    }
});

const AuthStateApp: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    checkToken();
    return (<></>);

}

const checkToken = async () => {
    try {
        await Auth.currentSession();
        window.location.href = '#/kendra';
    } catch (err) {
        window.location.href = '/';
        authFederate();
    }
}

const authFederate = () => {
    Auth.federatedSignIn().then((result) => {
    }).catch((error) => {
        console.log(error);
    });
}

export default AuthStateApp;