import { createTheme } from "@mui/material/styles";

const createCustomTheme = (theme: any) =>
  createTheme({
    palette: {
      primary: {
        main: "#212121",
        light: "#FFA4A2",
        dark: "#AF4448",
      },
      background: {
        default: "#FFFFFF",
      },
    },
    typography: {
      fontFamily: [
        "Helvetica Neue",
        "BlinkMacSystemFont",
        "Roboto",
        "Heebo",
        "Segoe UI",
        "Arial",
        "sans-serif",
      ].join(","),
      button: {
        textTransform: "none",
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: "#212121",
          },
        },
      },

      MuiSwitch: {
        styleOverrides: {
          root: {
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: "#2155cd",
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "#2155cd",
            },
          },
        },
      },

      // MuiButton:{
      //   styleOverrides: {
      //     root: {
      //       '&:focus': {
      //         borderColor: '#2286C3',
      //         color:"#2286C3",
      //         outlinedPrimary:""
      //       },
      //     },
      //   },
      // }
    },
  });

const black = createCustomTheme("black");

const themes = {
  black,
};

export default themes;
