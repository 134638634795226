import React from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Tooltip from '@mui/material/Tooltip';
import { DEFAULT_SORT_ATTRIBUTE, SortOrderEnum } from "./constants";
import { AvailableSortingAttributesManager } from "./AvailableSortingAttributesManager";
import { SelectedSortingAttributeManager } from "./SelectedSortingAttributeManager";
import NativeSelect from '@mui/material/NativeSelect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  DocumentAttributeTitleLookup,
  DocumentAttributeKeys,
} from "../constants";

import "./ResultSorting.scss";

interface OwnProps {
  availableSortingAttributes: AvailableSortingAttributesManager;
  selectedSortingAttribute: SelectedSortingAttributeManager;
  translation:any;
  currentLang:any;
  onSortingAttributeChange: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onSortingOrderChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

type Props = OwnProps;
export class ResultSorting extends React.Component<Props> {
  getSortingAttributeSelectOptions = (attributeList: string[]) => {
    /* Using optgroup to apply css style for limiting attributes number displayed in dropdown */

    return (
      <>
        {/* Relevance is the first to show */}
        <option value={DEFAULT_SORT_ATTRIBUTE}>
         {this.props.translation("DEFAULT_SORT_ATTRIBUTE")}
          </option>

        {/* Shows Created at and Updated at after Relevance */}
        {attributeList.indexOf(DocumentAttributeKeys.CreatedAt) >= 0 ? (
          <option
            value={DocumentAttributeKeys.CreatedAt}
            selected={
              this.props.selectedSortingAttribute.getSelectedSortingAttribute() ===
              DocumentAttributeKeys.CreatedAt
            }
          >
          { this.props.translation(DocumentAttributeTitleLookup[DocumentAttributeKeys.CreatedAt])}
          </option>
        ) : null}
        {attributeList.indexOf(DocumentAttributeKeys.UpdatedAt) >= 0 ? (
          <option
            value={DocumentAttributeKeys.UpdatedAt}
            selected={
              this.props.selectedSortingAttribute.getSelectedSortingAttribute() ===
              DocumentAttributeKeys.UpdatedAt
            }
          >
            { this.props.translation(DocumentAttributeTitleLookup[DocumentAttributeKeys.UpdatedAt])}
          </option>
        ) : null}

        {/* Shows other date type attributes and then shows other types of attributes*/}
        {attributeList.map((attribute) => {
          if (
            attribute !== DocumentAttributeKeys.CreatedAt &&
            attribute !== DocumentAttributeKeys.UpdatedAt
          ) {
            return (
              <option
                value={attribute}
                selected={
                  this.props.selectedSortingAttribute.getSelectedSortingAttribute() ===
                  attribute
                }
              >
               { this.props.translation(DocumentAttributeTitleLookup[attribute])}
              </option>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  };

  renderSortingAttributeSelect = (attributeList: string[]) => {
    return (
      <NativeSelect
        name="select"
        className="sorting-attribute-select"
        onChange={this.props.onSortingAttributeChange}
        IconComponent={ExpandMoreIcon}
      >
        {this.getSortingAttributeSelectOptions(attributeList)}
      </NativeSelect>
    );
  };

  getSortOrderToggleButton = (sortingOrder: string | null) => {
    if (sortingOrder === SortOrderEnum.Desc) {
      return (
        <Tooltip title={this.props.translation("clickSortAsc")} placement="top" arrow>
          <IconButton
            onClick={this.props.onSortingOrderChange}
            disableRipple
          >
            <KeyboardDoubleArrowDownIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      );
    } else if (sortingOrder === SortOrderEnum.Asc) {
      return (
        <Tooltip title={this.props.translation("clickSortDesc")} placement="top" arrow>
          <IconButton
            onClick={this.props.onSortingOrderChange}
            disableRipple
          >
            <KeyboardDoubleArrowUpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      );
    }
  };

  render() {
    const sortingOrder = this.props.selectedSortingAttribute.getSelectedSortingOrder();
    return (
      <Box className="query-result-sorting-container">
        {this.renderSortingAttributeSelect(
          this.props.availableSortingAttributes.get()
        )}
        <Box className="sort-direction-button">
          {!sortingOrder && this.getSortOrderToggleButton(null)}
          {sortingOrder === SortOrderEnum.Desc &&
            this.getSortOrderToggleButton(SortOrderEnum.Desc)}
          {sortingOrder === SortOrderEnum.Asc &&
            this.getSortOrderToggleButton(SortOrderEnum.Asc)}
        </Box>
      </Box>
    );
  }
}
