import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";

const PrivateRoute = ({ Component, ...rest}: {Component: any}): any => {
    const [sessionFetched, setSessionFetched] = useState(false);
    const [currentSession, setCurrentSession]= useState(null);

    useEffect(()=>{
        Auth.currentSession().then((data)=>{
            setCurrentSession(data as any);
        }).catch((error)=>{
            setCurrentSession(null);
        }).then(()=>setSessionFetched(true));
    },[Component]); 
     
    return sessionFetched && (
            currentSession && (currentSession as any).isValid() 
            ? (<Component/>) 
            : (<Navigate to={{pathname: "/",}}/>) 
    );
}

export default PrivateRoute;