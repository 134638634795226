import Kendra from "aws-sdk/clients/kendra";
import _ from "lodash";
import React from "react";
import { facetConfiguration } from "../configuration";
import {
  DocumentAttributeKeys,
  DocumentAttributeValueTypeEnum,
} from "../constants";
import { AvailableFacetRetriever } from "../facets/AvailableFacetRetriever";
import { DataSourceFacet } from "../facets/components/DataSourceFacet";
import { DateFacet } from "../facets/components/DateFacet";
import { StringFacet } from "../facets/components/StringFacet";
import { i18n } from "../facets/constants";
import "../facets/Facets.scss";
import { SelectedFacetManager } from "../facets/SelectedFacetManager";
import {
  DataSourceNameLookup,
  IndexFieldNameToDocumentAttributeValueType,
  selectHasReachedMaxFiltersForFacet,
} from "../facets/utils";
import Box from '@mui/material/Box';
import { styled } from "@mui/system";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const BoxStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: "0 20px"
  },
  [theme.breakpoints.up("sm")]: {
    padding: "0 32px"
  },
  [theme.breakpoints.up("md")]: {
    padding: "0 32px"

  },
  [theme.breakpoints.up("lg")]: {
    padding: "0 32px"
  },
  [theme.breakpoints.up("xl")]: {
    padding: "0 32px"
  },
}));

interface OwnProps {
  index?: Kendra.DescribeIndexResponse;
  attributeTypeLookup?: IndexFieldNameToDocumentAttributeValueType;
  availableFacets?: AvailableFacetRetriever;
  selectedFacets: SelectedFacetManager;
  open: boolean;
  resubmitQuery: any;
  clearAll: any;
  translation:any;

  onSelectedFacetsChanged: (
    updatedSelectedFacets: SelectedFacetManager
  ) => void;
  onExpand: () => void;

  // Required only if filtering by data source is desired
  dataSourceNameLookup?: DataSourceNameLookup;
}

type Props = OwnProps;
export class Facets extends React.Component<Props> {
  private onClickResetFilters = () => {
    this.props.onSelectedFacetsChanged(this.props.selectedFacets.clearAll());
  };

  onDateRangeSelectionsChange = (
    key: Kendra.DocumentAttributeKey,
    changeDetail?: [number, number]
  ) => {
    this.props.onSelectedFacetsChanged(
      this.props.selectedFacets.setDateRange(key, changeDetail)
    );
  };

  private onStringSelectionChange = (
    key: Kendra.DocumentAttributeKey,
    value: Kendra.DocumentAttributeValue,
    isSelected: boolean
  ) => {
    this.props.onSelectedFacetsChanged(
      this.props.selectedFacets.setIsSelected(key, value, isSelected)
    );
  };

  private onClearStringSelections = (key: Kendra.DocumentAttributeKey) => {
    this.props.onSelectedFacetsChanged(
      this.props.selectedFacets.clearSelectionsOf(key)
    );
  };

  private onClearStringAllSelections = () => {
    //this.props.resubmitQuery()

    this.props.clearAll();
  };

  private selectAllSelections = (
    key: Kendra.DocumentAttributeKey,
    value: Kendra.DocumentAttributeValue,
    isSelected: boolean
  ) => {
    this.props.onSelectedFacetsChanged(
      this.props.selectedFacets.setIsSelected(key, value, isSelected)
    );
  };

  render() {
    const {
      attributeTypeLookup,
      availableFacets,
      dataSourceNameLookup,
      selectedFacets,
      open,
    } = this.props;

    if (!availableFacets || !attributeTypeLookup || !selectedFacets) {
      return null;
    }

    const attributeNames = availableFacets.getAvailableAttributeNames();

    if (_.isEmpty(attributeNames)) {
      if (!selectedFacets.isEmpty()) {
        // Handle the edge case where we have filtered down to no results
        return (
          <div className="facets">
            <div className="facet">
              <span className="action-link" onClick={this.onClickResetFilters}>
                {i18n.RESET_FILTERS}
              </span>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    return (
      <Box
        className={open ? "facets-container facets-container--shown" : "facets-container facets-container--hidden"}
      >

        {
          attributeNames.map((attributeName) => {
            const type = attributeTypeLookup[attributeName];
            const disableAdd = selectHasReachedMaxFiltersForFacet(
              this.props.index,
              selectedFacets.getAllSelected(),
              attributeName
            );

            if (type === DocumentAttributeValueTypeEnum.DATE_VALUE) {
              return (
                <DateFacet
                  key={attributeName}
                  attributeName={attributeName}
                  valueCountPairs={availableFacets.get(attributeName)}
                  disableAdd={
                    disableAdd
                  } /* TODO need to test after link to the DateFacet */
                  selections={selectedFacets.getDateRangeSelectionsOf(
                    attributeName
                  )}
                  onSelectionsChange={this.onDateRangeSelectionsChange}
                />
              );
            } else if (attributeName === DocumentAttributeKeys.DataSourceId) {
              return (
                <DataSourceFacet
                  key={attributeName}
                  dataSourceNameLookup={dataSourceNameLookup}
                  disableAdd={disableAdd}
                  valueCountPairs={availableFacets.get(
                    DocumentAttributeKeys.DataSourceId
                  )}
                  facetConfiguration={facetConfiguration}
                  onSelectionChange={this.onStringSelectionChange}
                  onClear={this.onClearStringSelections}
                  selectedFacets={this.props.selectedFacets}
                />
              );
            } else {
              return (
                <StringFacet
                  key={attributeName}
                  attributeName={attributeName}
                  valueCountPairs={availableFacets.get(attributeName)}
                  disableAdd={disableAdd}
                  facetConfiguration={facetConfiguration}
                  onSelectionChange={this.onStringSelectionChange}
                  onClear={this.onClearStringSelections}
                  selectedFacets={this.props.selectedFacets}
                />
              );
            }
          })}
        <BoxStyled className="facet">
          <Container maxWidth="md">
            <Stack direction="row" sx={{ display: "flex", justifyContent: "flex-end", gap: "12px", padding: "12px 0" }}>
              <Button variant="outlined" className="facet-button" onClick={() => this.onClearStringAllSelections()}>{this.props.translation("clearFilter")}</Button>
              <Button variant="outlined" className="facet-button primary" onClick={() => this.props.resubmitQuery()}>{this.props.translation("applyFilter")}</Button>
            </Stack>
          </Container>
        </BoxStyled >
      </Box>
    );
  }
}
