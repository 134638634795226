import React from "react";
import Kendra from "aws-sdk/clients/kendra";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  AdditionalResultAttributeKeys,
  FAQExpandedMapType,
  FAQ_MATCHES,
  Relevance
} from "../constants";
import {
  isNullOrUndefined,
  isNullOrEmpty,
  selectMostRecentUpdatedTimestamp
} from "./../utils";

import ResultUrl from "./components/ResultUrl";
import ResultText from "./components/ResultText";
import ResultFooter from "./components/ResultFooter";
import TextHighlights from "./components/TextWithHighlight";
import "../search.scss";

interface FAQResultsProps {
  results: Kendra.QueryResultItemList;
  trans:any;

  submitFeedback: (
    relevance: Relevance,
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;
}

interface FAQResultsState {
  faqExpandedMap: FAQExpandedMapType[];
}

export default class FAQResults extends React.Component<
  FAQResultsProps,
  FAQResultsState
> {
  constructor(props: FAQResultsProps) {
    super(props);

    this.state = {
      faqExpandedMap: []
    };
  }

  componentDidMount() {
    if (!isNullOrEmpty(this.props.results)) {
      let expandedMap = [];
      for (var i = 0; i < 10; i++) {
        expandedMap.push({ expanded: false });
      }

      this.setState({ faqExpandedMap: expandedMap });
    }
  }

  handleExpansionClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    const previousValue = this.state.faqExpandedMap[index].expanded;
    let temp = this.state.faqExpandedMap;
    temp[index] = { expanded: !previousValue };
    this.setState({ faqExpandedMap: temp });
  };

  renderResults = (result: Kendra.QueryResultItem, index: number) => {
    const { submitFeedback } = this.props;
    let resultAttributes = Object();
    if (!isNullOrUndefined(result.AdditionalAttributes)) {
      result.AdditionalAttributes!.forEach(attribute => {
        resultAttributes[attribute.Key] = attribute.Value;
      });
    }

    const question =
      resultAttributes[AdditionalResultAttributeKeys.QuestionText];
    const answer = resultAttributes[AdditionalResultAttributeKeys.AnswerText];

    let title;
    if (!isNullOrUndefined(question)) {
      title = (
        <div className="faq-question action-link">
          <Typography>
            <TextHighlights
              textWithHighlights={question.TextWithHighlightsValue}
            />
          </Typography>
        </div>
      );
    }

    let content: Kendra.TextWithHighlights;
    if (!isNullOrUndefined(answer)) {
      content = answer.TextWithHighlightsValue;
    }

    let attributes = Object();
    if (!isNullOrUndefined(result.DocumentAttributes)) {
      result.DocumentAttributes!.forEach(attribute => {
        attributes[attribute.Key] = attribute.Value;
      });
    }

    const lastUpdated = selectMostRecentUpdatedTimestamp(attributes);

    return (
      <React.Fragment key={result.Id}>
        <Accordion className="faq-result">
          <AccordionSummary
            className="faq-title"
            expandIcon={<ExpandMoreIcon />}
          >
            {title}
          </AccordionSummary>
          <AccordionDetails className="faq-details">
            <ResultText
              className="small-margin-bottom"
              text={content!}
              lastUpdated={lastUpdated}
            />
            <ResultUrl
              queryResultItem={result}
              attributes={attributes}
              submitFeedback={submitFeedback}
            />
            <ResultFooter
              queryResultItem={result}
              attributes={attributes}
              submitFeedback={submitFeedback}
            />
          </AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  };

  render() {
    const { results } = this.props;

    if (isNullOrUndefined(results) || results.length === 0) {
      return null;
    }

    return (
      <div className="result-container card faq-container">
        <Typography className="card-title" variant="h3">{this.props.trans("kendraFAQ")}</Typography>
        <div>{results.map(this.renderResults)}</div>
      </div>
    );
  }
}
