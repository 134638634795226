import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';

import Kendra from "aws-sdk/clients/kendra";
import { attributeNameToFacetTitle } from "../utils";
import { i18n } from "../constants";
import { FacetCheckbox } from "./FacetCheckbox";
import "./Facet.scss";
import { FacetValue } from "./types";
import { SelectedFacetRetriever } from "../SelectedFacetRetriever";
//import _ from "lodash";
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';
import { styled } from "@mui/system";

const BoxStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: "0 20px"
  },
  [theme.breakpoints.up("sm")]: {
    padding: "0 32px"
  },
  [theme.breakpoints.up("md")]: {
    padding: "0 32px"

  },
  [theme.breakpoints.up("lg")]: {
    padding: "0 32px"
  },
  [theme.breakpoints.up("xl")]: {
    padding: "0 32px"
  },
}));

interface OwnProps {
  attributeName: string;
  values: FacetValue[];
  disableAdd: boolean;
  t:any;
  facetConfiguration: {
    facetsToShowWhenUncollapsed: number;
    showCount: boolean;
  };

  onSelectionChange: (
    key: Kendra.DocumentAttributeKey,
    value: Kendra.DocumentAttributeValue,
    changeDetail: boolean
  ) => void;

  onClear: (key: Kendra.DocumentAttributeKey) => void;

  selectedFacets?: SelectedFacetRetriever;
}

interface State {
  expanded: boolean;
}

type Props = OwnProps;
class Facet extends React.Component<Props, State,WithTranslation> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  showLess = () => {
    this.setState({
      expanded: false,
    });
  };

  showMore = () => {
    this.setState({
      expanded: true,
    });
  };

  onClickClearButton = () => {
    this.props.onClear(this.props.attributeName);
  };

  render() {
    const {
      attributeName,
      disableAdd,
      facetConfiguration,
      values,
    } = this.props;
    const { expanded } = this.state;

    const expandable =
      values.length > facetConfiguration.facetsToShowWhenUncollapsed;

    // const hasSomeValuesSelected = !_.isEmpty(
    //   this.props.selectedFacets?.getStringSelectionsOf(attributeName)
    // );

    return (
      <BoxStyled className="facet">
        <Container maxWidth="md">
          <div className="facet-wrapper">
            <div className="facet-title">
              {attributeNameToFacetTitle(attributeName)}&nbsp;
            </div>
            <div className="facet-value-container">
              {values.map((value, idx) => {
                if (!value.DocumentAttributeValue) {
                  return null;
                }

                const show =
                  expanded || idx < facetConfiguration.facetsToShowWhenUncollapsed;

                const isSelected = this.props.selectedFacets
                  ? this.props.selectedFacets.isSelected(
                    attributeName,
                    value.DocumentAttributeValue
                  )
                  : false;

                if (show || isSelected) {
                  return (
                    <FacetCheckbox
                      key={idx}
                      attributeName={attributeName}
                      value={value.DocumentAttributeValue}
                      valueLabel={value.ValueLabel}
                      count={value.Count}
                      selected={isSelected}
                      disableAdd={disableAdd}
                      onSelectionChange={this.props.onSelectionChange}
                      facetConfiguration={facetConfiguration}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>

            {expandable && expanded && (
              <div className="facet-footer">
                <span className="action-link" onClick={this.showLess}>
                  {this.props.t("showLess")}
                </span>
              </div>
            )}
            {expandable && !expanded && (
              <div className="facet-footer">
                <span className="action-link" onClick={this.showMore}>
                {this.props.t("showMore")}
                </span>
              </div>
            )}

          </div>
        </Container>
      </BoxStyled>
    );
  }
}

export default withTranslation()(Facet);

