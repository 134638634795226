import React,{useEffect} from 'react';
import NativeSelect from '@mui/material/NativeSelect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../redux/action/searchAction';

export default function App() {
    const { i18n } = useTranslation()
    const dispatch = useDispatch();
    const presentLanguage = useSelector((state: any) => state.SearchReducer.language);

    useEffect(() => {
        i18n.changeLanguage(presentLanguage)
      },[i18n, presentLanguage]);

    function changeLanguage(e: React.ChangeEvent<HTMLSelectElement>) {
        const { value } = e.target;
        i18n.changeLanguage(value)
        dispatch(setLanguage(value));
    }

    return (
        <NativeSelect
            inputProps={{
                name: 'languageSelector',
                id: 'languageSelector',
            }}
            sx={{
                color: 'white',
                fontSize: "15px",
                '& .MuiNativeSelect-icon': {
                    color: 'white',
                },
            }}
            value={presentLanguage}
            onChange={(e) => changeLanguage(e)}>
            <option value="en" style={{ color: 'black', fontSize: "15px" }}>English</option>
            <option value="ja" style={{ color: 'black', fontSize: "15px" }}>日本語</option>
        </NativeSelect>
    );
}
