import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';

import Kendra from "aws-sdk/clients/kendra";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import "../../search.scss";
import { localizedDate } from "../../utils";


const DocTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "13px",
  color: "#747b8a"
}));

interface AuthorTitleProps {
  queryResultItem: Kendra.QueryResultItem;
  attributes: any;
  lastUpdated?: Date;
  t:any
}

 class AuthorTitle extends React.Component<AuthorTitleProps,WithTranslation> {
  public formatDateString = (dateStr: Date) => {
    const date = localizedDate(new Date(dateStr), {
      day: "2-digit",
      month: "short",
      year: "numeric"
    });

    return `${date}`;
  };
  render() {
    const { attributes, lastUpdated } = this.props;
    const Author = attributes._authors ? attributes._authors.StringListValue[0] : "N/A"
    const PublishedOn = attributes._created_at ? attributes._created_at.StringValue : ""
    const PublishDate = PublishedOn ? this.formatDateString(PublishedOn) : undefined;
    const LastUpdatedDate = lastUpdated ? this.formatDateString(lastUpdated) : undefined;

    return (
      <Box sx={{ backgroundColor: "", display: "flex", flexDirection: "row", alignItems: "center" }}>
        <DocTitle>
        {this.props.t("author")}
          <Box sx={{ fontWeight: 600 }} display='inline'>
            &nbsp;{Author}&nbsp;
          </Box>
          <span className="sep">·</span>
          {this.props.t("published")}
          <Box sx={{ fontWeight: 600 }} display='inline'>
            &nbsp;{PublishDate}&nbsp;
          </Box>
          <span className="sep">·</span>
          {this.props.t("updated")}
          <Box sx={{ fontWeight: 600 }} display='inline'>
            &nbsp;{LastUpdatedDate}
          </Box>
        </DocTitle>
      </Box>
    )
  }
}
export default withTranslation()(AuthorTitle);