import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthStateApp from './AuthStateApp';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import {
  HashRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import { persistStore } from 'redux-persist';
import PrivateRoute from './routes/PrivateRoute';
import Store from './redux/store'

import "./i18n"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
persistStore(Store, {}, () => {
  root.render(<>
    <Provider store={Store}>
      <Router>
        <Routes>
          <Route path="/kendra" element={<PrivateRoute Component={App} />} />
          <Route path="/app" element={<AuthStateApp />} />
          <Route path="/" element={<AuthStateApp />} />
        </Routes>
      </Router>
    </Provider>
  </>)
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
