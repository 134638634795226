import React from "react";
import Kendra from "aws-sdk/clients/kendra";
import "../../search.scss";
import Feedback from "./Feedback";
import { Relevance } from "../../constants";

interface ResultFooterProps {
  queryResultItem: Kendra.QueryResultItem;
  attributes: any;

  submitFeedback: (
    relevance: Relevance,
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;
}

export default class ResultFooter extends React.Component<
  ResultFooterProps,
  {}
> {

  render() {
    const { queryResultItem, submitFeedback } = this.props;

    return (
      <div className="result-footer">
        <Feedback
          queryResultItem={queryResultItem}
          submitFeedback={submitFeedback}
        />
      </div>
    );
  }
}
