import React from "react";
import Kendra from "aws-sdk/clients/kendra";
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Switch from '@mui/material/Switch';
import { connect } from "react-redux";
import DocumentResults from "./DocumentResults";
import FAQResults from "./FAQResults";
import TopResults from "./TopResults";
import { PAGE_SIZE, Relevance } from "./../constants";
import { AvailableSortingAttributesManager } from "../sorting/AvailableSortingAttributesManager";
import { SelectedSortingAttributeManager } from "../sorting/SelectedSortingAttributeManager";
import { ResultSorting } from "../sorting/ResultSorting";
import { Facets } from "../filter/ResultFilter"
import { AvailableFacetRetriever } from "../facets/AvailableFacetRetriever";
import {
  DataSourceNameLookup,
  IndexFieldNameToDocumentAttributeValueType
} from "../facets/utils";
import { SelectedFacetManager } from "../facets/SelectedFacetManager";
import { setHighlightResult } from "../../redux/action/searchAction"
import "../search.scss";

import FormControlLabel from '@mui/material/FormControlLabel';
import Container from '@mui/material/Container';

const BoxStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    padding: "20px 20px"
  },
  [theme.breakpoints.up("sm")]: {
    padding: "20px 32px"
  },
  [theme.breakpoints.up("md")]: {
    padding: "20px 32px"

  },
  [theme.breakpoints.up("lg")]: {
    padding: "20px 32px"
  },
  [theme.breakpoints.up("xl")]: {
    padding: "20px 32px"
  },
}));


const PageIndexBox = styled(Box)(({ theme }) => ({
  width: "100%",
  zIndex: 100,
  top: 130,
  backgroundColor: "white",
  [theme.breakpoints.up('xs')]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px 0px 16px 0px",
    color: "grey",
    alignItems: "flex-start",
  },
  [theme.breakpoints.up('sm')]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px 0px 16px 0px",
    color: "grey",
    alignItems: "center",
  },
  [theme.breakpoints.up('md')]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "16px 0px 16px 0px",
    color: "grey",
    alignItems: "center",
  },
  [theme.breakpoints.up('lg')]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "16px 0px 16px 0px",
    color: "grey",
    alignItems: "center"
  },
  [theme.breakpoints.up('xl')]: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "16px 0px 16px 0px",
    color: "grey",
    alignItems: "center"
  },
}));

const SortFilter = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: "flex", width: "100%", justifyContent: "space-between"
  },
  [theme.breakpoints.up('sm')]: {
    display: "flex", width: "100%", justifyContent: "space-between"
  },
  [theme.breakpoints.up('md')]: {
    display: "flex", width: "100%", justifyContent: "space-between"
  },
  [theme.breakpoints.up('lg')]: {
    display: "flex", width: "100%", justifyContent: "space-between"
  },
  [theme.breakpoints.up('xl')]: {
    display: "flex", width: "100%", justifyContent: "space-between"
  },
}));

interface ResultsPanelProps {
  resubmitQuery: any;
  trans: any;
  currentLang: any;
  index?: Kendra.DescribeIndexResponse;
  availableFacets?: AvailableFacetRetriever;
  attributeTypeLookup?: IndexFieldNameToDocumentAttributeValueType;
  dataSourceNameLookup?: DataSourceNameLookup;
  selectedFacets: SelectedFacetManager;
  open: boolean;
  results: Kendra.QueryResult;
  topResults: Kendra.QueryResultItemList;
  faqResults: Kendra.QueryResultItemList;
  docResults: Kendra.QueryResultItemList;
  dataReady: boolean;
  currentPageNumber: number;
  queryText: string;
  isHighlighted: boolean;
  setHighlightResult: any;
  clearAll: any;
  availableSortingAttributes: AvailableSortingAttributesManager;
  selectedSortingAttribute: SelectedSortingAttributeManager;
  onExpand: () => void;
  onSelectedFacetsChanged: (
    updatedSelectedFacets: SelectedFacetManager
  ) => void;
  onSortingAttributeChange: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onSortingOrderChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;

  submitFeedback: (
    relevance: Relevance,
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;
}

interface ResultsPanelHighlightProps {
  highlightResult: boolean;
}

class ResultsPanel extends React.Component<ResultsPanelProps, ResultsPanelHighlightProps> {
  private renderPageIndex = () => {
    const { currentPageNumber, dataReady, results, queryText } = this.props;
    // Ensure spacing is always correct
    let pageIndex = <span>&nbsp;</span>;

    if (
      dataReady &&
      results?.TotalNumberOfResults &&
      results.TotalNumberOfResults > 0
    ) {
      const pageStart = (currentPageNumber - 1) * PAGE_SIZE + 1;
      const pageEnd = pageStart + (results.ResultItems!.length - 1);

      // Actually populate if ready
      const PRECISION = 3;
      let resultsLength: string = `${results.TotalNumberOfResults}`;
      if (resultsLength.length > PRECISION) {
        // Only get three most significant digits
        resultsLength =
          resultsLength.substr(0, PRECISION) +
          "0".repeat(resultsLength.length - PRECISION);
        // Add commas based on locale
        resultsLength = parseInt(resultsLength).toLocaleString();
      }

      if (resultsLength.length > PRECISION) {
        pageIndex = (
          <p className="result-stats">
          {this.props.currentLang === "ja" 
          ?(<>
            <span><b>"{queryText}"</b>を言及した<b>{resultsLength}</b>のファイルが見つかりました</span>
            <span className="result-stats-sep">|</span>
            <span> <b>{pageStart}-{pageEnd}</b> 件を表示しています</span></>) 
          :(<>
            <span>Found <b>{resultsLength}</b> files with mention of <b>"{queryText}"</b></span>
            <span className="result-stats-sep">|</span>
            <span>Showing<b>{pageStart}-{pageEnd}</b> results</span></>)}
        </p>
        );
      } else {
        pageIndex = (
          <p className="result-stats">
            {this.props.currentLang === "ja" 
            ?(<>
              <span><b>"{queryText}"</b>を言及した<b>{resultsLength}</b>のファイルが見つかりました</span>
              <span className="result-stats-sep">|</span>
              <span> <b>{pageStart}-{pageEnd}</b> 件を表示しています</span></>) 
            :(<>
              <span>Found <b>{resultsLength}</b> files with mention of <b>"{queryText}"</b></span>
              <span className="result-stats-sep">|</span>
              <span>Showing <b>{pageStart}-{pageEnd}</b> results</span></>)}
          </p>
        );
      }
    }

    return <div>{pageIndex}</div>;
  };

  render() {
    const {
      topResults,
      faqResults,
      docResults,
      dataReady,
      submitFeedback,
      isHighlighted,
      setHighlightResult
    } = this.props;

    if (dataReady) {
      const { QueryId } = this.props.results
      return (
        <div className="results-section">
          <BoxStyled className="filters-buttons-section">
            <Container maxWidth="md">
              <PageIndexBox className="search-buttons-container">
                <SortFilter>
                  <Box sx={{ display: "flex" }}>
                    <ResultSorting
                      availableSortingAttributes={this.props.availableSortingAttributes}
                      selectedSortingAttribute={this.props.selectedSortingAttribute}
                      onSortingAttributeChange={this.props.onSortingAttributeChange}
                      onSortingOrderChange={this.props.onSortingOrderChange}
                      translation={this.props.trans}
                      currentLang={this.props.currentLang}
                    />
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="outlined"
                      sx={{ borderColor: this.props.open ? "#2286C3" : "#C4C4C4", color: this.props.open ? "#2286C3" : "#666666" }}
                      onClick={this.props.onExpand}
                      className="filter-button"
                      disableRipple
                    >
                      {this.props.open ?
                        this.props.trans("hideFilter") : this.props.trans("showFilter")}
                    </Button>
                  </Box>
                </SortFilter>
              </PageIndexBox>

            </Container>
          </BoxStyled>
          {/* <Container maxWidth="md">
            <Stack spacing={2} direction="row" sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained">All Filter</Button>
              <Button variant="contained">Clear All Filter</Button>
            </Stack>
          </Container> */}
          <Facets
            availableFacets={this.props.availableFacets}
            attributeTypeLookup={this.props.attributeTypeLookup}
            dataSourceNameLookup={this.props.dataSourceNameLookup}
            onSelectedFacetsChanged={this.props.onSelectedFacetsChanged}
            selectedFacets={this.props.selectedFacets}
            index={this.props.index}
            open={this.props.open}
            resubmitQuery={this.props.resubmitQuery}
            clearAll={this.props.clearAll}
            onExpand={this.props.onExpand}
            translation={this.props.trans} />
          {/* <Facets
              availableFacets={this.props.availableFacets}
              attributeTypeLookup={this.props.attributeTypeLookup}
              dataSourceNameLookup={this.props.dataSourceNameLookup}
              onSelectedFacetsChanged={this.props.onSelectedFacetsChanged}
              selectedFacets={this.props.selectedFacets}
              index={this.props.index}
              open={this.props.open}
              onExpand={this.props.onExpand} /> */}

          <BoxStyled className="result-items-section">
            <Container maxWidth="md">
              <Box sx={{ display: "flex" }} className="result-items-control-section">
                {this.renderPageIndex()}

                <FormControlLabel control={<Switch
                  checked={isHighlighted}
                  onChange={() => setHighlightResult(!isHighlighted)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />} label={this.props.trans("highlightSearch")} />
              </Box>

              <TopResults results={topResults} submitFeedback={submitFeedback} />

              <FAQResults results={faqResults} submitFeedback={submitFeedback} trans={this.props.trans} />

              <DocumentResults
                results={docResults}
                Query={QueryId}
                submitFeedback={submitFeedback}
              />
            </Container>
          </BoxStyled>
        </div>
      );
    } else {
      return undefined;
    }
  }
}


const mapStateToProps = (state: any) => ({
  isHighlighted: state.SearchReducer.highlightResult
});

const mapDispatchToProps = {
  setHighlightResult,
};


export default connect(mapStateToProps, mapDispatchToProps)(ResultsPanel);