/* eslint-disable import/no-anonymous-default-export */
const HIGHLIGHT_RESULT = 'HIGHLIGHT_RESULT';
const SET_LANGUAGE = 'SET_LANGUAGE';

const initialState = {
highlightResult: true,
language:"ja"
}

export default function (state = initialState, action: { type: any; data: any; }) {
  switch (action.type) {
    case HIGHLIGHT_RESULT:
      return { ...state, highlightResult: action.data }
    case SET_LANGUAGE:
      return { ...state, language: action.data }  
    default:
      return state
  }
}