import React from "react";
import Kendra from "aws-sdk/clients/kendra";

import { Relevance } from "../../constants";
import "../../search.scss";
import IconButton from '@mui/material/IconButton';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownAltRounded';

interface FeedbackProps {
  queryResultItem: Kendra.QueryResultItem;
  submitFeedback: (
    relevance: Relevance,
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;
}

interface FeedbackState {
  relevance?: Relevance;
}

export default class Feedback extends React.Component<
  FeedbackProps,
  FeedbackState
> {
  constructor(props: FeedbackProps) {
    super(props);

    this.state = {
      relevance: undefined
    };
  }

  private setRelevance(relevance: Relevance) {
    this.setState({ ...this.state, relevance });
  }

  private submitFeedback = (relevance: Relevance) => () => {
    this.setRelevance(relevance);
    this.props.submitFeedback(relevance, this.props.queryResultItem);
  };

  render() {
    const { relevance } = this.state;

    let likeIcon;
    if (relevance === Relevance.Relevant) {
      likeIcon = <ThumbUpRoundedIcon style={{ color: "#2155CD" }} />;
    } else {
      likeIcon = <ThumbUpRoundedIcon />;
    }

    let dislikeIcon;
    if (relevance === Relevance.NotRelevant) {
      dislikeIcon = <ThumbDownRoundedIcon style={{ color: "#2155CD" }} />;
    } else {
      dislikeIcon = <ThumbDownRoundedIcon />;
    }

    return (
      <div className="feedback-buttons">
        <IconButton
          aria-label="like"
          className="feedback-button"
          onClick={this.submitFeedback(Relevance.Relevant)}
          size="medium"
        >
          {likeIcon}
        </IconButton>
        <IconButton
          aria-label="dislike"
          className="feedback-button"
          onClick={this.submitFeedback(Relevance.NotRelevant)}
          size="medium"
        >
          {dislikeIcon}
        </IconButton>
      </div>
    );
  }
}
