import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import SearchReducer from "../reducer/searchReducer";
import thunk from 'redux-thunk'

const rootReducer = combineReducers({
  SearchReducer,
});

const persistConfig = {
  key: 'kendra_localStore',
  storage,
}
const composeEnhancers = compose;
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(thunk),
));

export default store;