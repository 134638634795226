const Environment = {
    env: process.env.REACT_APP_ENV,
    aws: {
        cognitoDomain : process.env.REACT_APP_COGNITO_DOMAIN,
        region : process.env.REACT_APP_REGION,
        responseType : process.env.REACT_APP_RESPONSE_TYPE,
        clientId : process.env.REACT_APP_CLIENT_ID,
        userPoolId : process.env.REACT_APP_USER_POOL_ID,
        redirectURL : process.env.REACT_APP_REDIRECT_URI,
        cognitoScope : process.env.REACT_APP_COGNITO_SCOPE
    },
    app: {
        authorisedKey : 'kendra_authorised',
        kendra: process.env.REACT_APP_HCP_APP_HOME
    }    
}

export default Environment;
