/* eslint-disable react/forbid-prop-types */
import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import { styled } from '@mui/system';
import { useNavigate } from "react-router-dom";
import ExperimentalAppBar from './appBar';
import LanguageSwitcher from "../appDropdown/dropDown"

const AppLogo = require('../../asset/enterprise_search_hub_logo.svg').default;
const AppLogoMobile = require('../../asset/enterprise_search_hub_logo_mobile.svg').default;

const LeftButtonGroupStyled = styled(ButtonGroup)(({theme}) => ({
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  cursor:"pointer",

  [theme.breakpoints.up('xs')]: {
    height: '48px',
  },
  [theme.breakpoints.up('sm')]: {
    height: '56px',
  },
  [theme.breakpoints.up('md')]: {
    height: '56px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '56px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '56px',
  },
}));

const AppLogoStyled = styled('img')(({theme}) => ({
  padding: '0px',
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'flex',
  },
}));

const AppLogoStyledMobile = styled('img')(({theme}) => ({
  padding: '0px',
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'none',
  },
}));

// Main Function based Component Starts here
export default function AppBarChildren() {
  const navigate = useNavigate();
  const appBarLeftChildren = () => (
    <LeftButtonGroupStyled onClick={()=>navigate(0)}>
      <AppLogoStyled src={ AppLogo} alt="AZ 2D Barcode App Logo" />
      <AppLogoStyledMobile src={  AppLogoMobile} alt="AZ 2D Barcode App Logo" />
    </LeftButtonGroupStyled>
  );

  const appBarRightChildren = () => (
      <LanguageSwitcher/>
  );

  return (
    <>
      <ExperimentalAppBar
        leftChildren={appBarLeftChildren()}
        rightChildren={appBarRightChildren()}
      />
    </>
  );
}

