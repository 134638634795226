import React from "react";
import Kendra from "aws-sdk/clients/kendra";
import Typography from '@mui/material/Typography';

import { isNullOrUndefined, localizedDate } from "../../utils";

import TextHighlights from "./TextWithHighlight";
import "./../../search.scss";

interface ResultTextProps {
  lastUpdated?: Date;
  text: Kendra.TextWithHighlights;
  className?: string;
}

export default class ResultText extends React.Component<ResultTextProps, {}> {
  /**
   * Render text without truncation
   */
  private renderNotTruncated = (text: Kendra.TextWithHighlights) => {
    return (
      <Typography>
        <TextHighlights textWithHighlights={text} />
      </Typography>
    );
  };

  private renderResult = () => {
    const { text } = this.props;

    if (isNullOrUndefined(text)) {
      return null;
    } else {
      return this.renderNotTruncated(text);
    }
  };

  public formatDateString = (dateStr: Date) => {
    const date = localizedDate(new Date(dateStr), {
      day: "2-digit",
      month: "short",
      year: "numeric"
    });

    return `${date} - `;
  };

  render() {
    return (
      <div className="result-text">
        <span >{this.renderResult()}</span>
      </div>
    );
  }
}
