import Kendra from "aws-sdk/clients/kendra";
import React from "react";
import {
  Form,
} from "react-bootstrap";
import { withTranslation, WithTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash.debounce';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton'
import { styled } from "@mui/system";
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import "../search.scss";
import Tooltip from '@mui/material/Tooltip';

const SUGGESTIONS_KEYSTROKE_DELAY = 200; // adjust as needed
const MIN_TEXT_LENGTH_FOR_SUGGESTIONS = 2;
const MAX_TEXT_LENGTH_FOR_SUGGESTIONS = 60;


const BoxStyled = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
  },
  [theme.breakpoints.up("md")]: {

  },
  [theme.breakpoints.up("lg")]: {
  },
  [theme.breakpoints.up("xl")]: {
  },
}));

interface SearchBarProps {
  onSubmit: (queryText: string, pageNumber: number) => void;
  suggestionsEnabled: boolean;
  clearSearch: () => void,
  getQuerySuggestions: (queryText: string) => Promise<Kendra.Types.SuggestionList | undefined>;
  t:any;
}

interface SearchBarState {
  queryText: string;
  suggestions: Kendra.Types.SuggestionList;
}

class SearchBar extends React.Component<
  SearchBarProps,
  SearchBarState,
  WithTranslation
> {
  constructor(props: SearchBarProps) {
    super(props);

    this.state = {
      queryText: "",
      suggestions: [],
    };

    this.populateSuggestions = debounce(this.populateSuggestions, SUGGESTIONS_KEYSTROKE_DELAY);
  }

  onChange = (
    e: any
  ) => {
    this.setState({ queryText: e.target.value })
    const { suggestionsEnabled } = this.props;
    if (suggestionsEnabled && e.target.value.length >= MIN_TEXT_LENGTH_FOR_SUGGESTIONS && e.target.value.length <= MAX_TEXT_LENGTH_FOR_SUGGESTIONS) {
      this.populateSuggestions(e.target.value);
    } else {
      this.setState({ suggestions: [] })
    }
  }

  populateSuggestions = (
    value: string
  ) => {
    const { getQuerySuggestions } = this.props;
    var that = this;

    getQuerySuggestions(value).then(function (result) {
      if (result) {
        that.setState({ suggestions: result })
      }
    });
  }

  clearQueryAndResults() {
    this.setState({
      queryText: ""
    })
    this.props.clearSearch()
  }

  onSearch = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    // callback to the API call
    const { onSubmit } = this.props;
    onSubmit(this.state.queryText, 1);
  };

  onSelect = (
    value: string
  ) => {
    const { onSubmit } = this.props;
    this.setState({ queryText: value });
    onSubmit(value, 1);
  };

  buildSuggestionItems() {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return [];
    }
    return suggestions.map(suggestion => this.buildSuggestionItemWithHighlightedLabel(suggestion));
  }

  buildSuggestionItemWithHighlightedLabel = (
    suggestion: Kendra.Types.Suggestion
  ) => {
    if (suggestion && suggestion.Value && suggestion.Value.Text) {
      let suggestionText = suggestion.Value.Text.Text;
      const suggestionHighlights = suggestion.Value.Text.Highlights;
      if (suggestionText && suggestionHighlights) {
        const suggestionHighlight = suggestionHighlights[0];
        if (suggestionHighlight && suggestionHighlight.BeginOffset) {
          // split text at BeginOffset and bold second part
          let label = (<span>
            {suggestionText.substring(0, suggestionHighlight.BeginOffset)}
            <strong>{suggestionText.substring(suggestionHighlight.BeginOffset)}</strong>
          </span>);
          return ({ label: label, key: suggestionText });
        }
      }
      // set suggestionText as label if no highlights
      return ({ label: suggestionText, key: suggestionText });
    }
  }

  showSearchForm = () => {
    const { queryText } = this.state;
    const suggestionItems = this.buildSuggestionItems();
    return (
      <Form
        noValidate
        onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
          this.onSearch(event)
        }
      >
        <BoxStyled>
          <Autocomplete
            freeSolo
            value={queryText}
            disableClearable={true}
            options={suggestionItems.map((option) => option ? option.key : [])}
            fullWidth={true}
            renderInput={(params) =>
              <TextField
                {...params}
                className="search-field"
                placeholder={this.props.t("searchBarPlaceHolder")}
                onChange={(e) => this.onChange(e)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {queryText && (
                        <Tooltip title={this.props.t("clearToolTip")}  arrow>
                          <IconButton
                            aria-label="Clear Search Field"
                            onClick={() => this.clearQueryAndResults()}
                            disableRipple
                            size="large"
                          >
                            {/*<Image src={ClearIcon} />*/}
                            <ClearIcon />
                          </IconButton></Tooltip>)}
                      <Tooltip title={this.props.t("searchToolTip")} arrow>
                        <IconButton
                          aria-label="Search Field"
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                            this.onSearch(event)
                          }
                          disableRipple
                          size="large"
                        >
                          <SearchIcon className="search-icon" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            }
          />
        </BoxStyled>
      </Form>
    );
  };

  render() {
    return <div style={{ position: "sticky", width: "100%", zIndex: 100, top: 65, height: "auto", opacity: 1, }}>{this.showSearchForm()}</div>;
  }
}

export default withTranslation()(SearchBar);