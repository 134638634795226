import React from "react";
import Kendra from "aws-sdk/clients/kendra";
import { connect } from "react-redux";

import { isNullOrUndefined } from "../../utils";
import "../../search.scss";

interface HighlightedTextProps {
  text: string | undefined;
  highlight: Kendra.Highlight;
  isHighlighted: boolean;
}

 class HighlightedText extends React.Component<
  HighlightedTextProps,
  {}
> {
  render() {
    const { text, highlight, isHighlighted } = this.props;
    // all highlights are bolded
    // if TopAnswer is provided and true, it has a yellow background
    return (
      <strong
        className={
          isHighlighted
            ? "search-term"
            : ""
        }
      >
        {!isNullOrUndefined(text) &&
          text!.substring(highlight.BeginOffset, highlight.EndOffset)}
      </strong>
    );
  }
}

const mapStateToProps = (state: any) => ({
  isHighlighted: state.SearchReducer.highlightResult
});

export default connect(mapStateToProps)(HighlightedText);
