import React from "react";
import * as _ from "lodash";
import Kendra from "aws-sdk/clients/kendra";
import Box from '@mui/material/Box';
import TextHighlights from "./TextWithHighlight";
import "../../search.scss";
import { Relevance } from "../../constants";
import { Icon } from '@fluentui/react';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import Tooltip from '@mui/material/Tooltip';
import PDFIcon from "../../images/PDF_24.png";
import CSVFileIcon from "../../images/file_icons/CSV.svg";
import HTMLFileIcon from "../../images/file_icons/HTML.svg";
import JSONFileIcon from "../../images/file_icons/JSON.svg";
import MDFileIcon from "../../images/file_icons/MD.svg";
import PDFFileIcon from "../../images/file_icons/PDF.svg";
import RTFFileIcon from "../../images/file_icons/RTF.svg";
import XMLFileIcon from "../../images/file_icons/XML.svg";
import XSLTFileIcon from "../../images/file_icons/XSLT.svg";



interface ResultTitleProps {
  queryResultItem: Kendra.QueryResultItem;
  attributes: any;

  submitFeedback: (
    relevance: Relevance,
    resultItem: Kendra.QueryResultItem
  ) => Promise<void>;
}

export default class ResultTitle extends React.Component<ResultTitleProps, {}> {
  private submitClickFeedback = () => {
    this.props.submitFeedback(Relevance.Click, this.props.queryResultItem);
  };

  render() {
    const { queryResultItem } = this.props;

    const uri = queryResultItem.DocumentURI;

    const FileType = this.props.attributes._file_type;
    function documentExt() {
      const File_Ext = FileType ? FileType.StringValue : ""
      return File_Ext
    }

    // title is not guaranteed to exist, show nothing if that's the case
    let resultTitle: React.ReactNode = null;
    if (
      queryResultItem.DocumentTitle &&
      !_.isEmpty(queryResultItem.DocumentTitle.Text)
    ) {
      resultTitle = (
        <TextHighlights textWithHighlights={queryResultItem.DocumentTitle} />
      );
    } else if (queryResultItem.DocumentURI) {
      resultTitle = queryResultItem.DocumentURI;
    } else {
      return null;
    }

    if (uri && !_.isEmpty(uri)) {
      resultTitle = (
        <a
          className="action-link result-title"
          href={uri}
          onClick={this.submitClickFeedback}
          target="_blank"
          rel="noopener noreferrer"
        >
          {resultTitle}
        </a>
      );
    }

    let fileIcon;
    if (documentExt() === "MS_WORD") {
      fileIcon = <Icon {...getFileTypeIconProps({ extension: 'docx', size: 20, imageFileType: 'svg' })} />;
    } else if (documentExt() === "MS_EXCEL") {
      fileIcon = <Icon {...getFileTypeIconProps({ extension: 'xlsx', size: 20, imageFileType: 'svg' })} />;
    } else if (documentExt() === "PPT") {
      fileIcon = <Icon {...getFileTypeIconProps({ extension: 'pptx', size: 20, imageFileType: 'svg' })} />;
    } else if (documentExt() === "CSV") {
      /*fileIcon = <Icon {...getFileTypeIconProps({ extension: 'csv', size: 20, imageFileType: 'svg' })} />;*/
      fileIcon = <img style={{ width: "20px" }} src={CSVFileIcon} alt="CSV Icon" />;
    } else if (documentExt() === "PDF") {
      fileIcon = <img style={{ width: "20px" }} src={PDFIcon} alt="PDF Icon" />;
      /*fileIcon = <img style={{ width: "20px" }} src={PDFFileIcon} />;*/
    } else if (documentExt() === "HTML") {
      fileIcon = <img style={{ width: "20px" }} src={HTMLFileIcon} alt="HTML Icon" />;
    } else if (documentExt() === "JSON") {
      fileIcon = <img style={{ width: "20px" }} src={JSONFileIcon} alt="JSON Icon" />;
    } else if (documentExt() === "MD") {
      fileIcon = <img style={{ width: "20px" }} src={MDFileIcon} alt="MD Icon" />;
    } else if (documentExt() === "PDF") {
      fileIcon = <img style={{ width: "20px" }} src={PDFFileIcon} alt="PDFFile Icon" />;
    } else if (documentExt() === "RTF") {
      fileIcon = <img style={{ width: "20px" }} src={RTFFileIcon} alt="RTF Icon" />;
    } else if (documentExt() === "XML") {
      fileIcon = <img style={{ width: "20px" }} src={XMLFileIcon} alt="XML Icon" />;
    } else if (documentExt() === "XSLT") {
      fileIcon = <img style={{ width: "20px" }} src={XSLTFileIcon} alt="XSLT Icon" />;
    } else {
      fileIcon = "";
    }

    return (
      <Box>
        {resultTitle}
        <Tooltip title={documentExt()} placement="right" arrow>
          <span className="result-file-icon">
            {fileIcon}
          </span>
        </Tooltip>
      </Box>
    )
  }
}
