const HIGHLIGHT_RESULT = 'HIGHLIGHT_RESULT';
const SET_LANGUAGE = 'SET_LANGUAGE';

 const setHighlightResult = (payload) => ({
  type: HIGHLIGHT_RESULT,
  data: payload
})

const setLanguage = (payload) => ({
  type: SET_LANGUAGE,
  data: payload
})

export {setHighlightResult,setLanguage}