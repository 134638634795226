
const AWSExports = {
    "region" : process.env.REACT_APP_COGNITO_REGION as string,
    "AccountId" : process.env.REACT_APP_COGNITO_ACCT_ID as string,
    "IdentityPoolId" : process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID as string,
    "indexId" : process.env.REACT_APP_COGNITO_INDEX_ID as string,
    "RoleSessionName" : process.env.REACT_APP_COGNITO_ROLE_SESSION_NAME as string,
    "RoleArn" : process.env.REACT_APP_COGNITO_ROLE_ARN as string
}
 
export default AWSExports;

